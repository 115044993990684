import $api from "../http";

export default class ProjectService {
  static async getProjects() {
    return $api.get("/projects", { follow: true });
  }
  static async addProject(name) {
    return $api.post("/projects", { name });
  }
  static async getProject(id) {
    return $api.get(`/projects/${id}`);
  }
  static async updateProject(id, name) {
    return $api.patch(`/projects/${id}`, { name });
  }
  static async deleteProject(id) {
    return $api.delete(`/projects/${id}`);
  }
  static async submitNewFeed(
    parent,
    projectName,
    projectMode,
    projectUrl,
    projectFile,
    type
  ) {
    const formData = new FormData();
    formData.append("project", parent);
    formData.append("name", projectName);
    formData.append("mode", projectMode);
    formData.append("type", type);
    if (projectMode === 1) {
      formData.append("file", projectFile);
    } else {
      formData.append("url", projectUrl);
    }
    return $api.post("/feeds/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  static async getFeed(id) {
    return $api.get(`/feeds/${id}`);
  }
  static async removeFeed(id) {
    return $api.delete(`/feeds/${id}`);
  }
  static async updateFeed(id, interval, name) {
    return $api.patch(`/feeds/${id}`, { interval, name });
  }
  static async getFeedColumns(id) {
    return $api.get(`/feeds/${id}/columns`);
  }
  static async getFeedTypes() {
    return $api.get(`/feeds/types`);
  }
  static async getFeedRows(id, pagenum, pageSize, filters, sorting) {
    return $api.post(`/feeds/${id}/rows`, {
      filters,
      sorting,
      params: {
        page: pagenum,
        size: pageSize,
      },
    });
  }
  static async addNewColumn(id, columnname, def = '') {
    return $api.post(`/feeds/${id}/columns`,{
      "name": columnname,
      "attr_name": "param",
      "type": 6,
      "default": def
    });
  }
  static async addColumnWithType(tableid,columnname,attr_name, type, def) {
    return $api.post(`/feeds/${tableid}/columns`,{
      "name": columnname,
      "attr_name": attr_name,
      "type": type,
      "default": def
    });
  }
  static async editCell(id,value,row,cell) {
    return $api.patch(`/feeds/${id}/values`,{
      "column": cell,
      "row": row,
      "value": value
    });
  }
  static async deleteColumn(id,column) {
    return $api.delete(`/feeds/${id}/columns/${column}`);

  }
  static async renameColumn(id,column,newname) {
    return $api.patch(`/feeds/${id}/columns/${column}`,{
      name: newname
    });
  }
  static async getFeedOverview(id) {
    return $api.get(`/feeds/${id}/dashboard`);
  }
  static async updateFeedOverview(id) {
    return $api.post(`/feeds/${id}/dashboard`);
  }
  static async getFeedErrors(id) {
    return $api.get(`/feeds/${id}/dashboard/errors`);
  }
  static async getFeedStructure(id) {
    return $api.get(`/feeds/${id}/dashboard/structure`);
  }
  static async getFeedContent(id) {
    return $api.get(`/feeds/${id}/dashboard/content`);
  }
  static async addNewFeed(id,name) {
    return $api.post(`/feeds/${id}/outgoing`,{
      "name": name
    });
  }

// ИНТЕГРАЦИИ
  static async getIntegrationsTypes() {
    return $api.get(`/integrations/types`);
  }
  static async addIntegrations(project, name, type, data) {

    return $api.post(`/integrations`,{
      "project": project,
      "name": name,
      "type": type,
      "data": data
    });
  }
  static async updateIntegration(id, name, data) {
    return $api.patch(`/integrations/${id}`,{
      "name": name,
      "data": data
    });
  }
  static async deleteIntegration(id) {
    return $api.delete(`/integrations/${id}`);
  }
  static async getIntegrations(project, type = null) {
    return $api.get(`/integrations?project=${project}`,{
      "project": project,
      "type": type,
    });
  }
  static async getIntegrationsZoomos(integration_id) {
    return $api.get(`/integrations/${integration_id}/zoomos`);
  }
  static async getIntegrationsGoogle(integration_id) {
    return $api.get(`/integrations/${integration_id}/google`);
  }
// FILTERS

static async getFilters(tableid) {
  return $api.get(`/feeds/${tableid}/filters`)
}
static async addFilter(tableid,filter) {
  return $api.post(`/feeds/${tableid}/filters`,filter)
}

static async removeFilter(tableid, filterid) {
  return $api.delete(`/feeds/${tableid}/filters`,{
    data: {
      filter: filterid
    }
  });
}


  // static async setFilters(id,filters) {
  //   return $api.post(`/feeds/${id}/outgoing`,{
  //     "filters": filters
  //   });
  // }

//XLSX

static async downloadFeedXlsx(tableid) {
  return $api.get(`/feeds/${tableid}/xlsx`);
}


// Charts

  static async getFeedChartOverview(id) {
    return $api.get(`/feeds/${id}/chart`);
  }

  static async addFilterFeedChart(id, date_to = "", date_from = "") {
    return $api.post(`/feeds/${id}/chart`,{
      "date_to": date_to,
      "date_from": date_from
    });
  }


}
