import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";

const AddNewFeed = ({ tableid, addNewFeed }) => {
  // SAVE NEW FEED
  const [addNewFeedModalVisible, setAddNewFeedModalVisible] = useState(false);
  const [newFeedName, setNewFeedName] = useState('');
  const [loading, setLoading] = useState(false);

  const showModalSaveNewFeed = () => {
    setAddNewFeedModalVisible(true);
  };

  const handleAddNewFeedModalOk = async () => {
    setLoading(true);
    await addNewFeed(tableid, newFeedName).then((response) => {
      console.log(response);
      setAddNewFeedModalVisible(false);
      setNewFeedName('');
      setLoading(false);
    }).catch(() => {
      // Если произошла ошибка, сбросьте состояние загрузки
      setLoading(false);
    });
  };

  const handleAddNewFeedModalCancel = () => {
    setAddNewFeedModalVisible(false);
  };

  return (
    <>
      <Button
        className="btn"
        onClick={showModalSaveNewFeed}
       
        size="large"
        type="primary"
      >
        Сохранить новый фид
      </Button>
      <Modal
        open={addNewFeedModalVisible}
        onOk={handleAddNewFeedModalOk}
        confirmLoading={loading}
        onCancel={handleAddNewFeedModalCancel}
        title="Сохранение нового фида"
      >
        <Form.Item>
          <Input
            value={newFeedName}
            placeholder="Укажите название"
            onChange={(e) => setNewFeedName(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export default AddNewFeed;
