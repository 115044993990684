import React from "react";
import {  Input, Upload } from "antd";

const { Dragger } = Upload;

const AddProjectTableFile = ({ fileChange, urlChange, mode, projectUrl, feedType }) => {
  return (
    <>
      {mode === 1 && (
        <Dragger
          key={"AddProjectTableFileDragger"}
          multiple={false}
          maxCount={1}
          beforeUpload={(file) => {
            fileChange(file)
            return false; // Возвращаем false, чтобы предотвратить автоматическую загрузку файла
          }}
        >
          <p className="AddProjectTableFileDraggerIcon">
          <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.4919 11.7199H13.781V8.00897C13.781 7.90155 13.6931 7.81366 13.5857 7.81366H12.4138C12.3064 7.81366 12.2185 7.90155 12.2185 8.00897V11.7199H8.50757C8.40015 11.7199 8.31226 11.8078 8.31226 11.9152V13.0871C8.31226 13.1945 8.40015 13.2824 8.50757 13.2824H12.2185V16.9933C12.2185 17.1008 12.3064 17.1887 12.4138 17.1887H13.5857C13.6931 17.1887 13.781 17.1008 13.781 16.9933V13.2824H17.4919C17.5994 13.2824 17.6873 13.1945 17.6873 13.0871V11.9152C17.6873 11.8078 17.5994 11.7199 17.4919 11.7199Z" fill="#4A5EFF"/>
<path d="M13 1.5625C6.95996 1.5625 2.0625 6.45996 2.0625 12.5C2.0625 18.54 6.95996 23.4375 13 23.4375C19.04 23.4375 23.9375 18.54 23.9375 12.5C23.9375 6.45996 19.04 1.5625 13 1.5625ZM13 21.582C7.98535 21.582 3.91797 17.5146 3.91797 12.5C3.91797 7.48535 7.98535 3.41797 13 3.41797C18.0146 3.41797 22.082 7.48535 22.082 12.5C22.082 17.5146 18.0146 21.582 13 21.582Z" fill="#4A5EFF"/>
</svg>

          </p>
          <p className="ant-upload-text">
          Щелкните или перетащите файл в эту область, чтобы загрузить
            {feedType === 1 ? <span>Форматы: .xml, .yml</span> : <span>Форматы: .csv</span>}
            {/*<span>Форматы: .xml, .yml</span>*/}
          </p>
        </Dragger>
      )}
      {mode === 2 && (
        <div>

                <Input
                  value={projectUrl}
                  style={{width: '100%'  }}
                  placeholder="Введите URL-адрес"
                  onChange={(value) => urlChange(value)}
                />

        </div>
      )}
    </>
  );
};

export default AddProjectTableFile;
